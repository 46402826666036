import React, { useEffect } from 'react';
import Tooltip from '@mui/material/Tooltip';
import SuiBox from '../../../components/SuiBox';
import SuiInput from '../../../components/SuiInput';
import SuiCreatableSelect from '../../../components/SuiCreatableSelect';
import SuiTypography from '../../../components/SuiTypography';
import DocumentApi from '../../../api/documentApi';
import { AttributeTypeEnum } from '../../../utils/AttributeTypeEnum';
import { FileDataItem } from '../../../types/fileDataItem';
import { FileData } from '../../../types/fileData';
import FlashingBox from './FlashingBox';

interface PDFInfoDisplayProps {
  user: {
    refreshToken: string;
  };
  fileData: FileData;
  setFileData: (data: FileData) => void;
  setMadeUnsavedChanges: (changed: boolean) => void;
  processNewFields: ({diaType}: {diaType: string}) => Promise<void>;
}

const PDFInfoDisplay: React.FC<PDFInfoDisplayProps> = ({
  user,
  fileData,
  setFileData,
  setMadeUnsavedChanges,
  processNewFields,
}) => {
  const divStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  const handleChange = async (index: number, value: string, documentType: string): Promise<void> => {
    setMadeUnsavedChanges(true);
    const newData = { ...fileData };
    if (fileData[documentType][index].name === 'diaType') {
      let oldDIAType = fileData[documentType][index].value;
      if (oldDIAType != value) {
        processNewFields({
          diaType: value
        });
      }
    }
    if (
      fileData[documentType][index].inputType === 'dropdown' &&
      fileData[documentType][index].options &&
      !fileData[documentType][index].options.includes(value)
    ) {
      const newAttribute = newData[documentType];
      newAttribute[index].options?.push(value);
      newData[documentType] = newAttribute;
    }
    newData[documentType][index].value = value;
    setFileData(newData);
  };

  const renderListItem = (item: FileDataItem, index: number) => {
    return (
      <SuiBox
        key={item.key}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        marginBottom="8px"
      > 
      
        {item.inputType != 'hidden' && (
          <SuiTypography
            component="label"
            variant="caption"
            fontWeight="bold"
            style={{ fontSize: '16px' }}
        >
            {item.label}
          </SuiTypography>
        )}
        <div style={{ width: '60%' }}>
          {item.loading && (
            <FlashingBox />
          )}
          {!item.loading && (
            <>
            {item.inputType === 'dropdown' && (
              <> 
                <SuiCreatableSelect
                  success={!item.options?.includes(item.value)}
                  onChange={({ value }: { value: string }) =>
                    handleChange(index, value, item.attributeType)
                  }
                  onCreateOption={async (value: string) => {
                    let type = item.entityType || item.name;
                    await DocumentApi.addGeneralFieldOption(
                      type,
                      value,
                      user.refreshToken
                    );
                    handleChange(index, value, item.attributeType);
                  }}
                  options={item.options?.map((option) => ({
                    label: option,
                    value: option,
                  })) || []}
                  value={{ label: item.value, value: item.value }}
                />
              </>
            )}
            {item.inputType === 'editable' && (
              <>
                  <SuiInput
                    key={item.value}
                    defaultValue={item.value || ''}
                    onBlur={(event: React.FocusEvent<HTMLInputElement>) =>
                      handleChange(index, event.target.value, item.attributeType)
                    }
                    aria-label={item.label}
                />
              </>
            )}
            {item.inputType == 'disabled' && (
              <>
                {item.value.startsWith('https') ? (
                  <a href={item.value} target="_blank" rel="noopener noreferrer">
                    <SuiTypography
                      variant="caption"
                      fontWeight="bold"
                      style={{ fontSize: '16px', color: 'blue', textDecoration: 'underline' }}
                    >
                      {item.value}
                    </SuiTypography>
                  </a>
                ) : (
                  <SuiTypography
                    variant="caption"
                    fontWeight="bold"
                    style={{ fontSize: '16px' }}
                  >
                    {item.value}
                  </SuiTypography>
                )}
              </>
            )}
            </>
          )}
        </div>
      </SuiBox> 
    );
  };

  return (
    <SuiBox width="100%" overflowY="auto">
      <div style={divStyle}> Document General Attributes</div>
      {fileData[AttributeTypeEnum.DOCUMENT_ATTRIBUTE].map((item, index) =>
        renderListItem(item, index)
      )}

      <div style={divStyle}> Document Identity Attributes</div>
      {fileData[AttributeTypeEnum.DOCUMENT_IDENTITY_ATTRIBUTE].map(
        (item, index) => renderListItem(item, index)
      )}

      {fileData[AttributeTypeEnum.DOCUMENT_TYPE_SPECIFIC_ATTRIBUTE].length !== 0 && 
        <div style={divStyle}> Document Specific Attributes </div>
      }
      {fileData[AttributeTypeEnum.DOCUMENT_TYPE_SPECIFIC_ATTRIBUTE].map(
        (item, index) => renderListItem(item, index)
      )}
    </SuiBox>
  );
};

export default PDFInfoDisplay;
function processNewFields(arg0: { diaType: string; }) {
  throw new Error('Function not implemented.');
}

