import axios from './index';

class Drive {
  static base: string = 'drive';

  static async saveDocument({
    refreshToken,
    documentId,
    destinationFilePath,
    newName,
  }: {
    refreshToken: string;
    documentId: string;
    destinationFilePath: string;
    newName: string;
  }): Promise<any> {
    return axios.post(
      `${this.base}/save?refreshToken=${refreshToken}&documentId=${documentId}&destinationFilePath=${destinationFilePath}&newName=${newName}`
    );
  }

  static async getFoldersPaths(token: string): Promise<any> {
    return await axios.get(
      `${this.base}/getFoldersPaths/?refreshToken=${token}`,
      {
        headers: { Authorization: `${token}` },
      }
    );
  }

  /**
   * uploads pdf to drive using base64 data
   * @param base64Data
   * @param destinationFilePath
   * @param newName
   * @param token
   */
  static uploadBase64FileToDrive(
    base64Data: string,
    destinationFilePath: string,
    newName: string,
    token: string
  ): Promise<any> {
    const formData = new FormData();
    formData.append('base64Data', base64Data);
    formData.append('destinationFilePath', destinationFilePath);
    formData.append('newName', newName);
    formData.append(
      'refreshToken',
      JSON.parse(localStorage.getItem('user') || '{}').refreshToken
    );

    return axios.post(`${this.base}/upload`, formData, {
      headers: {
        Authorization: `${token}`,
        'Content-Type': 'multipart/form-data', // This header ensures that the server can parse the files correctly
      },
    });
  }

  //Pooya
  static getNewDriveFiles(token: string, userEmail: string): Promise<any> {
    return axios.get(
      `${this.base}/getNewDriveFiles?refreshToken=${token}&userEmail=${userEmail}`,
      {
        headers: { Authorization: `${token}` },
      }
    );
  }

  static getNewFiles(token: string, forceNoNewTypes: boolean = true): Promise<any> {
    return axios.get(`${this.base}/getNewFiles?refreshToken=${token}&forceNoNewTypes=${forceNoNewTypes}`);
  }
}

export default Drive;
