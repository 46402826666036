import React from 'react';
import { useHistory } from 'react-router-dom';
import SuiBox from '../../components/SuiBox';
import SuiButton from '../../components/SuiButton';
import CoverLayout from './components/CoverLayout';
import curved9 from '../../assets/images/curved-images/curved-6.jpg';
import { useAuth } from '../../auth-context/auth.context';
import { Google, Microsoft } from '@mui/icons-material';
import { useGoogleLogin } from '@react-oauth/google';
import User from '../../types/user';
import Auth from '../../api/auth';
import { Divider } from '@mui/material';

const SignIn: React.FC = () => {
  const history = useHistory();
  const { setUser, user } = useAuth();

  const authenticateUserGoogle = useGoogleLogin({
    onSuccess: async ({ code }: { code: string }) => {
      const tokens = await Auth.authenticateGoogle(code);
      console.log(tokens);
      setUser(tokens);
      history.push('/dashboard');
    },
    flow: 'auth-code',
    scope:
      'https://www.googleapis.com/auth/drive https://www.googleapis.com/auth/gmail.modify https://www.googleapis.com/auth/pubsub',
  });

  const authenticateUserMicrosoft = async () => {
    // TODO: refactor values to variables
    window.location.href = 'http://localhost:5001/auth/microsoft';
  };

  const setProfile = async (response: any): Promise<void> => {
    const user: User = { ...response.data.user };
    user.refreshToken = response.data.token;
    user.userEmail = response.data.user.email || '';
    const userString = JSON.stringify(user);
    setUser(user);
    localStorage.setItem('user', userString);
    history.push('/dashboard');
  };

  return (
    <CoverLayout
      title="Your Smart File Assistant"
      description={`${user && user.refreshToken ? '' : 'Sign in to get started.'}`}
      image={curved9}
    >
      {user && user.refreshToken ? (
        <div>
          <h3 style={{ textAlign: 'center' }}>You are already signed in.</h3>
          <SuiBox mt={4} mb={1}>
            <SuiButton
              component="button"
              variant="gradient"
              buttonColor="info"
              fullWidth
              onClick={() => history.push('/dashboard')}
            >
              Let's go
            </SuiButton>
          </SuiBox>
        </div>
      ) : (
        <SuiBox>
          <SuiButton
            component="label"
            buttonColor="secondary"
            startIcon={<Google />}
            onClick={authenticateUserGoogle}
            fullWidth
          >
            Authenticate Google
          </SuiButton>
          <Divider />
          <SuiButton
            component="label"
            buttonColor="secondary"
            startIcon={<Microsoft />}
            onClick={authenticateUserMicrosoft}
            fullWidth
          >
            Authenticate Microsoft
          </SuiButton>
        </SuiBox>
      )}
    </CoverLayout>
  );
};

export default SignIn;
