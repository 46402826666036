import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../auth-context/auth.context';

const SignOut: React.FC = (): JSX.Element | null => {
  const history = useHistory();
  const { setUser } = useAuth();
  const { user } = useAuth();

  const handleLogout = async (): Promise<void> => {
    console.log('Initiating logout for user:', user); // Log the user that is being logged out

    try {
      // const response = await AuthApi.Logout(user);
      // console.log('Logout response:', response); // Log the response from the logout attempt

      setUser(null);
      localStorage.removeItem('user');
      console.log('User state cleared and redirected to sign-in page');

      history.push('/authentication/sign-in');
    } catch (error) {
      console.error('Logout error:', error); // Log any errors during logout
    }
  };

  useEffect(() => {
    console.log('SignOut component mounted, executing logout');
    handleLogout();
    // Adding handleLogout to the dependency array can cause repeated calls if handleLogout changes,
    // so ensure handleLogout is stable or correctly memoized if added to dependencies
  }, [handleLogout]);

  return null;
};

export default SignOut;
