import React, { useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import { DataGrid } from '@mui/x-data-grid';
import SuiBox from '../../../../components/SuiBox';
import SuiTypography from '../../../../components/SuiTypography';
import Card from '@mui/material/Card';
import DocumentViewer from '../DocumentViewer';
import Button from '@mui/material/Button';


import AddIcon from '@mui/icons-material/Add';

// Styling for tables
import styles from '../../../../layouts/tables/styles';
import DocumentApi from '../../../../api/documentApi';
import { useAuth } from '../../../../auth-context/auth.context';
import { AttachFile } from '@mui/icons-material';
import Drive from '../../../../api/drive';
import CircularProgress from '@mui/material/CircularProgress';
import { DocumentSchema } from '../../../../types/document';
import { getColumns } from './tableData';
import { sortRows } from '../../../../utils/utils';
import AwsApi from '../../../../api/aws';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const FilesTable: React.FC = () => {
  const [selectedIndex, setSelectedIndex] = useState<number>(-1);
  const [rows, setRows] = useState<DocumentSchema[]>([]);
  const columns = getColumns(rows, setSelectedIndex);
  const [update, setUpdate] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [downloadLink, setDownloadLink] = useState<string | null>(null);
  const [downloading, setDownloading] = useState<boolean>(false);
  const { user } = useAuth();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const classes = styles();

  const getNewFiles = async (): Promise<void> => {
    setLoading(true);
    console.log("calling new files")
    await Drive.getNewFiles(
      JSON.parse(localStorage.getItem('user') || '{}').refreshToken,
      false,
    );
    setLoading(false);
    setUpdate(!update);
  };

  useEffect(() => {
    const fetchDocuments = async () => {
      const response = await DocumentApi.getDocumentsByType(null, user.refreshToken);
      if (response) {
        let newRows = response.data.map((doc, index) => ({
          ...doc,
          id: doc._id || index,
        }));

        for (let i = 0; i < newRows.length; i++) {
          const s3Response = await AwsApi.fileFromS3(newRows[i]._id, user.refreshToken);
          if (s3Response.data.Body) {
              const blob = new Blob([new Uint8Array(s3Response.data.Body.data)], {
              type: 'application/pdf',
            });
            newRows[i].url = URL.createObjectURL(blob);
          }
        }
        setRows(sortRows(newRows));
        console.log(newRows);
      }
    };

    fetchDocuments();
  }, [update]);

  useEffect(() => {
    if (selectedIndex === -1) {
      setUpdate(!update);
    }
  }, [selectedIndex]);

  const uploadFiles = async (files: FileList) => {
    console.log(files);
    if (!files) return;
    
    let uploadingRows: DocumentSchema[] = [];
    const startRowIndex = rows.length;

    Array.from(files).forEach((file, index) => {
      const newRow: DocumentSchema = {
        id: startRowIndex + index,
        daFileName: file.name,
        diaType: 'Default',
        daUploader: user.userEmail,
        daStatus: 'Uploading',
      };
      uploadingRows.push(newRow);
    });
    let allRows = [...uploadingRows, ...rows]; // append new rows to the start of the table
    setRows(sortRows(allRows));

    //upload each file, then analyze it
    Array.from(files).forEach(async (file, index) => {
      try {
        let document = await DocumentApi.uploadFile({
          fileName: file.name,
          file: file,
          daUploader: user.userEmail,
          token: user.refreshToken
        });
        allRows[index].daStatus = 'Analyzing';
        setRows(sortRows(allRows)); // update status
        await DocumentApi.analyzeDocument(
          document.data.documentId,
          user.refreshToken
        );
        setUpdate(!update);
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    });
  };

  const confirmDone = async (file: DocumentSchema) => {
    file.daStatus = 'Done';
    //go to next 'review required' file, wrapping around to start if needed
    let startIndex = (selectedIndex + 1) % rows.length;
    let i = startIndex;
    do {
      if (rows[i].daStatus === 'Review Required') {
        setSelectedIndex(i);
        return;
      }
      i = (i + 1) % rows.length;
    } while (i !== startIndex);
    
    //no review required files found after checking all
    setSelectedIndex(-1);
  };

 
  const handleDownloadExcel = async () => {
    setDownloading(true);
    try {
      const response = await DocumentApi.downloadExcel(user.refreshToken);
      let reponse = response.data.link;
      setDownloadLink(reponse);
    } catch (error) {
      console.error('Error downloading Excel file:', error);
    } finally {
      setDownloading(false);
    }
  };

  return (
    <SuiBox py={3}>
      {/* <SuiDropzone onDrop={e => uploadFiles(e)} /> */}
      <DocumentViewer
        file={rows[selectedIndex]}
        numFiles={rows.length}
        selectedIndex={selectedIndex}
        setSelectedIndex={setSelectedIndex}
        confirmDone={() => confirmDone(rows[selectedIndex])}
      />
      <Card>
        <SuiBox display="flex" alignItems="center" p={3}>
          <SuiTypography variant="h6">Recent Files</SuiTypography>
          <Button
            component="label"
            color="secondary"
            startIcon={<AttachFile />}
            onClick={getNewFiles}
          >
            Process Files From Google
            {loading && <CircularProgress size={20} />}
          </Button>
          <Button component="label" color="secondary" startIcon={<AddIcon />}>
            Upload file
            <VisuallyHiddenInput
              ref={fileInputRef}
              type="file"
              onChange={e => uploadFiles(e.target.files)}
              multiple
            />
          </Button>
          <Button
            component="a"
            onClick={handleDownloadExcel}
          >
            {downloading ? 'Saving excel on your google drive' : 'Export to Excel'}
          </Button>
          {downloadLink && !downloading && (
            <span style={{ marginLeft: '10px' }}>
              <a href={downloadLink} target="_blank" rel="noopener noreferrer">
                {downloadLink}
              </a>
            </span>
          )}
        </SuiBox>
        <SuiBox className={classes.tables_table}>
          <DataGrid rows={rows} columns={columns} />
        </SuiBox>
      </Card>
    </SuiBox>
  );
};

export default FilesTable;
