
// Soft UI Dashboard React layouts
import Dashboard from './layouts/dashboard/dashboard';
import RenamingLab from './layouts/renamingLab/renamingLab';
// import Billing from "layouts/billing";
// import Profile from "layouts/profile";
import SignIn from './layouts/authentication/signIn';
import SignUp from './layouts/authentication/signUp';
import SignOut from './layouts/authentication/signOut';

// Soft UI Dashboard React icons
import Shop from './examples/Icons/Shop';
import Document from './examples/Icons/Document';
import SpaceShip from './examples/Icons/SpaceShip';
import { RELEASE_TAG } from './config/constant';
import { Edit, TableChart, TableChartRounded } from '@mui/icons-material';
import SheetsLab from './layouts/sheetsLab/sheetsLab';
import Office from './examples/Icons/Office';
// import CustomerSupport from "examples/Icons/CustomerSupport";
// import CreditCard from "examples/Icons/CreditCard";

interface RouteType {
  type: 'title' | 'collapse' | 'none' | 'divider';
  title?: string;
  name?: string;
  key: string;
  route?: string;
  icon?: React.ReactNode;
  component?: React.ComponentType;
  noCollapse?: boolean;
  protected?: boolean;
}

const routes: RouteType[] = [
  { type: 'title', title: 'Main Pages', key: 'main-pages' },
  {
    type: 'collapse',
    name: 'Dashboard',
    key: 'dashboard',
    route: '/dashboard',
    icon: <Shop size="12px" />,
    component: Dashboard,
    noCollapse: true,
    protected: true,
  },
  {
    type: 'collapse',
    name: 'Renaming Lab',
    key: 'renaming-lab',
    route: '/renaming-lab',
    icon: <Edit />,
    component: RenamingLab,
    noCollapse: true,
    protected: true,
  },
  {
    type: 'collapse',
    name: 'Sheets Lab',
    key: 'sheets-lab',
    route: '/sheets-lab',
    icon: <TableChartRounded />,
    component: SheetsLab,
    noCollapse: true,
    protected: true,
  },
  // {
  //   type: 'collapse',
  //   name: 'Projects',
  //   key: 'projects',
  //   route: '/projects',
  //   icon: <Office size="12px" />,
  //   component: Tables,
  //   noCollapse: true,
  //   protected: true,
  // },

  { type: 'title', title: 'Account Pages', key: 'account-pages' },
  /* {
    type: "collapse",
    name: "Profile",
    key: "profile",
    route: "/profile",
    icon: <CustomerSupport size="12px" />,
    component: Profile,
    noCollapse: true,
    protected: true,
  },
  {
    type: "collapse",
    name: "Billing",
    key: "billing",
    route: "/billing",
    icon: <CreditCard size="12px" />,
    component: Billing,
    noCollapse: true,
    protected: true,
  }, */
  {
    type: 'none',
    name: 'Sign In',
    key: 'sign-in',
    route: '/authentication/sign-in',
    icon: <Document size="12px" />,
    component: SignIn,
    noCollapse: true,
  },
  {
    type: 'none',
    name: 'Sign Up',
    key: 'sign-up',
    route: '/authentication/sign-up',
    icon: <SpaceShip size="12px" />,
    component: SignUp,
    noCollapse: true,
  },
  // {
  //   type: 'collapse',
  //   name: 'Settings',
  //   key: 'settings',
  //   route: '/settings',
  //   icon: <SettingsIcon size="12px" />,
  //   component: Settings,
  //   noCollapse: true,
  //   protected: true,
  // },
  {
    type: 'collapse',
    name: 'Logout',
    key: 'sign-out',
    route: '/authentication/sign-out',
    icon: <SpaceShip size="12px" />,
    component: SignOut,
    noCollapse: true,
  },
  {
    type: 'divider',
    key: 'divider',
  },
  {
    type: 'title',
    title: `App Version: ${RELEASE_TAG}`,
    key: 'app-version',
    icon: <SpaceShip size="12px" />,
    noCollapse: true,
  },
];

export default routes;
