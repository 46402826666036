import React from 'react';

interface PDFViewerProps {
  url: string;
  style?: React.CSSProperties;
}

const PDFViewer: React.FC<PDFViewerProps> = React.memo(({ url, style }) => {
  const iframeStyle: React.CSSProperties = {
    width: '100%',
    height: '100vh', 
    border: 'none',
    ...style,
  };

  return (
    <iframe
      style={iframeStyle}
      src={`${url}#view=Fit`} // #view=Fit help to fit doc to the bounds
      title="PDF Viewer"
      name="pdf-viewer"
    ></iframe>
  );
});

export default PDFViewer;
