import React, { useEffect, useMemo, useState } from 'react';
import { Button, CircularProgress } from '@mui/material';
import PDFViewer from './PDFViewer';
import type { DocumentSchema } from '../../../types/document';
import Drive from '../../../api/drive';
import { useAuth } from '../../../auth-context/auth.context';
import PDFInfoDisplay from './PDFInfoDisplays';
import DocumentApi from '../../../api/documentApi';
import { convertObjectToList } from '../../../utils/utils';
import { AttributeTypeEnum } from '../../../utils/AttributeTypeEnum';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'; // "Previous" button
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'; // "Next" button
import { FileData } from '../../../types/fileData';
import AccountApi from '../../../api/accountApi';
import { AccountConfig } from '../../../types/accountConfig';
import SuiBox from '../../../components/SuiBox';
import SuiTypography from '../../../components/SuiTypography';
import SuiAlert from '../../../components/SuiAlert';

interface DocumentViewerPageProps {
  file: DocumentSchema;
  numFiles: number;
  selectedIndex: number;
  setSelectedIndex: (index: number) => void;
  confirmDone: () => void;
}

const DocumentViewerPage: React.FC<DocumentViewerPageProps> = ({
                                                                 file,
                                                                 numFiles,
                                                                 selectedIndex,
                                                                 setSelectedIndex,
                                                                 confirmDone,
                                                               }) => {
  /* handle the screen layout */
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [fileData, setFileData] = useState<FileData>({
    [AttributeTypeEnum.DOCUMENT_ATTRIBUTE]: [],
    [AttributeTypeEnum.DOCUMENT_IDENTITY_ATTRIBUTE]: [],
    [AttributeTypeEnum.DOCUMENT_TYPE_SPECIFIC_ATTRIBUTE]: [],
  });
  const {user} = useAuth(); // Use the useAuth hook to get the current user
  const [madeUnsavedChanges, setMadeUnsavedChanges] = useState<boolean>(false);
  const [suggestedFilePath, setSuggestedFilePath] = useState<string>('');
  const [suggestedFileName, setSuggestedFileName] = useState<string>('');

  const pdfViewerUrl = useMemo(() => file?.url, [file?.url]);

  /* for overflowed scroll bars */
  useEffect(() => {
    if (isVisible) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isVisible]);

  useEffect(() => {
    setFilePathAndFileName();
  }, [fileData]);

  useEffect(() => {
    const populateDocumentFields = async () => {
      if (selectedIndex >= 0 && file) {
        let dIATypeOptions = file['diaType'];

        const allDocumentAttributes =
          await DocumentApi.getAttributesByDocumentType(
            dIATypeOptions,
            user.refreshToken
          );

        const defaultAttributes = allDocumentAttributes.data.default.fields || [];
        const specificAttributes =
          allDocumentAttributes.data.requestedType?.fields || [];
        const filledAttributes = convertObjectToList(file);
        const allAttributes = [...defaultAttributes, ...specificAttributes]

        const newFileData = await buildFieldMatching(
          allAttributes,
          filledAttributes
        );
        console.log(newFileData);
        setFileData(newFileData);
        setIsVisible(true);
      }
    };

    populateDocumentFields();
  }, [file, selectedIndex, user.refreshToken]);

  const setFilePathAndFileName = async (): Promise<void>   => {
        //set suggested file path and file name
        let accountConfigResponse = await AccountApi.getAccountConfig(user.refreshToken);
        let accountConfig: AccountConfig = accountConfigResponse.data;
        let fileNameSchema: string[] = accountConfig.fileNameSchema;
        let filePathSchema: string[] = accountConfig.filePathSchema;
        //get suggested file path and file name
        let aSuggestedFileName: string[] = [];
        let aSuggestedFilePath: string[] = [];

        Object.keys(fileData).forEach(key => {
          for(let j = 0; j < fileNameSchema.length; j++) {
            let fileNameField = fileData[key].find(e => e.name === fileNameSchema[j]);
            let filePathField = fileData[key].find(e => e.name === filePathSchema[j]);
            if (fileNameField) {
              aSuggestedFileName.push(fileNameField?.value);
            }
            if (filePathField) {
              aSuggestedFilePath.push(filePathField?.value);
            }
          }
        });
    setSuggestedFileName(aSuggestedFileName.join('_'));
    setSuggestedFilePath(aSuggestedFilePath.join('//'));
  }

  const buildFieldMatching = async (allAttributes: any[], filledAttributes: any[]): Promise<FileData> => {
    const completeAttributes = initiateNewFileData();
    console.log(filledAttributes);
    console.log(allAttributes);
    for (let i = 0; i < allAttributes.length; i++) {
      const field = {
        key: i,
        label: allAttributes[i].label,
        name: allAttributes[i].name,
        inputType: allAttributes[i].inputType,
        attributeType: allAttributes[i].attributeType,
        value: '',
        options: [],
        loading: false,
        entityType: '',
      };
      if (allAttributes[i].inputType === 'dropdown') {
        const options = await DocumentApi.getGeneralFieldOptions(
          allAttributes[i].entityType || allAttributes[i].name,
          user.refreshToken
        );
        field.entityType = allAttributes[i].entityType;
        field.options = options;
      }
      for (let j = 0; j < filledAttributes.length; j++) {
        if (
          allAttributes[i]?.name &&
          allAttributes[i]?.name === filledAttributes[j]?.name
        ) {
          field.value = filledAttributes[j].value;
          break;
        }
      }
      completeAttributes[field.attributeType].push(field);
    }
    return completeAttributes;
  };

  const warnMadeUnsavedChanges = (): boolean => {
    if (madeUnsavedChanges) {
      setMadeUnsavedChanges(false);
      return window.confirm(
        'You have unsaved changes. Are you sure you want to continue?'
      );
    }
    return true;
  };

  const showNextPDF = (): void => {
    if (warnMadeUnsavedChanges()) {
      const newIndex = (selectedIndex + 1 + numFiles) % numFiles;
      setSelectedIndex(newIndex);
    }
  };

  const showPrevPDF = (): void => {
    if (warnMadeUnsavedChanges()) {
      const newIndex = (selectedIndex - 1 + numFiles) % numFiles;
      setSelectedIndex(newIndex);
    }
  };

  const clearPDFs = (): void => {
    if (warnMadeUnsavedChanges()) {
      console.log('Clearing PDFs');
      setFileData(initiateNewFileData());
      setSelectedIndex(-1);
      setIsVisible(!isVisible);
    }
  };
  const initiateNewFileData = (): FileData => {
    return {
      [AttributeTypeEnum.DOCUMENT_ATTRIBUTE]: [],
      [AttributeTypeEnum.DOCUMENT_IDENTITY_ATTRIBUTE]: [],
      [AttributeTypeEnum.DOCUMENT_TYPE_SPECIFIC_ATTRIBUTE]: [],
    };
  };

  const handleConfirmUpload = async (): Promise<void> => {
    const daStatusField = fileData[AttributeTypeEnum.DOCUMENT_ATTRIBUTE].find(
      (e) => e.name === 'daStatus'
    );
    if (daStatusField) {
      daStatusField.value = 'Done';
    }

    const updatedFileData: any[] = convertObjectToList(fileData);
    console.log(updatedFileData);

    try {
      let documentType = file.diaType;
      let existingTypes = await DocumentApi.getGeneralFieldOptions(
        'diaType',
        user.refreshToken
      );
      if (!existingTypes.includes(documentType)) {
        await DocumentApi.createDocumentType(
          {type: documentType},
          user.refreshToken
        );
      }
      confirmDone();
      updatedFileData.unshift({
        defaultDocumentId: file._id
      });

      console.log(updatedFileData);
      await DocumentApi.updateDocument(updatedFileData, user.refreshToken);
      await Drive.saveDocument({
        refreshToken: user.refreshToken,
        documentId: file._id,
        destinationFilePath: suggestedFilePath,
        newName: suggestedFileName
      });
      //make file done
    } catch (error: any)  {
      console.error('Error uploading file:', error);
      alert(`Error during file upload: ${error.message}`);
    }
  };

  const processNewFields = async ({
    diaType,
  }: {
    diaType: string
  }): Promise<void> => {

    const dTSAs = await DocumentApi.getDTSAsByDocumentType(
      diaType,
      user.refreshToken
    );
    let fields = dTSAs.data.fields;


    setFileData(prevFileData => ({
      ...prevFileData,
      [AttributeTypeEnum.DOCUMENT_TYPE_SPECIFIC_ATTRIBUTE]: fields
    }));
    let objectToFill = {};
    for (let i = 0; i < fields.length; i++) {
      objectToFill[fields[i].name] = "";
    }

    for (let i = 0; i < fields.length; i++) {
      fields[i].loading = true;
    }

    setFileData(prevFileData => ({
      ...prevFileData,
      [AttributeTypeEnum.DOCUMENT_TYPE_SPECIFIC_ATTRIBUTE]: fields
    }));

    let suggestionsResponse = await DocumentApi.processDTSAValues({
      documentId: file._id,
      objectToFill, 
      token: user.refreshToken
    });
    let suggestions = suggestionsResponse.data;

    for (let i = 0; i < fields.length; i++) {
      fields[i].value = suggestions[fields[i].name];
      if (fields[i].inputType === 'dropdown') {
        fields[i].options = await DocumentApi.getGeneralFieldOptions(
          fields[i].value,
          user.refreshToken
        );
      }
      fields[i].loading = false;
    }

    console.log(fields);

    setFileData(prevFileData => ({
      ...prevFileData,
      [AttributeTypeEnum.DOCUMENT_TYPE_SPECIFIC_ATTRIBUTE]: fields
    }));
  }

  return (
    <div>
      {isVisible && file && (
        <>
          <div
            style={{
              display: 'flex',
              position: 'fixed',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: 10000,
              width: '100vw',
              height: '100vh',
              backgroundColor: 'rgba(255,255,255)',
            }}
          >
            <div style={{width: '80%', height: '100%'}}>
              <PDFViewer url={pdfViewerUrl} />
            </div>

            <div
              style={{
                width: '55%',
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
              }}
            >
              <div
                style={{
                  flex: 1,
                  overflowY: 'auto',
                  position: 'relative',
                  padding: '20px',
                }}
              >
                {isLoading ? (
                  <CircularProgress/>
                ) : (
                  fileData && (
                    <PDFInfoDisplay
                      user={user}
                      fileData={fileData}
                      setFileData={setFileData}
                      setMadeUnsavedChanges={setMadeUnsavedChanges}
                      processNewFields={processNewFields}
                    />
                  )
                )}
              </div>
              {file.daStatus === 'Done' && (
              <SuiBox>
                <SuiAlert
                  color="success"
                  dismissible={false}
                  icon="check"
                  title="New Files"
                  style={{
                    textAlign: 'center',
                  }}
                >
                    <SuiTypography
                      variant="caption"
                      fontWeight="bold"
                      style={{
                        color: 'white',
                      }}
                    >
                      Done
                    </SuiTypography>
                </SuiAlert>
              </SuiBox>
              )}
              <SuiBox>
                <SuiBox
                  variant="caption"
                  fontWeight="bold"

                  style={{
                    padding: '5px 20px',
                    fontSize: '16px',
                    borderTop: '1px solid #ccc',
                  }}
                >
                  <SuiBox
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    File Path
                    <SuiTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      style={{ 
                        fontSize: '16px',
                        marginLeft: '10px',
                        // marginRight: '50px'
                      }}
                    >
                      Organized/{suggestedFilePath.split('//').join('/')}
                    </SuiTypography>
                  </SuiBox>
                  <SuiBox
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                      File Name
                    <SuiTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      style={{ fontSize: '16px' }}
                    >
                      {suggestedFileName}
                    </SuiTypography>

                  </SuiBox> 
                </SuiBox>
                <SuiBox
                  style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: '10px',
                  borderTop: '1px solid #ccc',
                  backgroundColor: 'rgba(255,255,255,0.65)',
                }}
              >
                <Button onClick={showPrevPDF} startIcon={<ArrowBackIosIcon/>}>
                  Previous
                </Button>
                <span
                  style={{
                    marginLeft: '10px',
                    marginRight: '10px',
                    color: '#005DB4',
                  }}
                >
                  {`${selectedIndex + 1} of 
                  ${numFiles}
                  `}
                </span>
                <Button onClick={showNextPDF} endIcon={<ArrowForwardIosIcon/>}>
                  Next
                </Button>
              </SuiBox>

              <SuiBox
                style={{
                  display: 'flex',
                  justifyContent: 'space-around',
                  padding: '10px',
                  backgroundColor: 'rgba(255,255,255,0.65)',
                }}
              >
                <Button
                  variant="contained"
                  onClick={clearPDFs}
                  style={{
                    background: '#7E7F80',
                    color: 'white',
                    borderRadius: 15,
                    padding: '5px 25px',
                  }}
                >
                  cancel
                </Button>
                <Button
                  variant="contained"
                  onClick={handleConfirmUpload}
                  style={{
                    background: '#005DB4',
                    color: 'white',
                    borderRadius: 15,
                    padding: '5px 25px',
                  }}
                >
                  Confirm
                  </Button>
                </SuiBox>
              </SuiBox>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default DocumentViewerPage;
