/**
=========================================================
* Soft UI Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { forwardRef, ReactNode } from 'react';

// clsx is a utility for constructing className strings conditionally
import clsx from 'clsx';

// @mui material components
import InputBase from '@mui/material/InputBase';
import Icon from '@mui/material/Icon';

// Soft UI Dashboard PRO React components
import SuiBox from '../SuiBox';

// Custom styles for SuiInput
import styles from './styles';

// Soft UI Dashboard PRO React contexts
// import { useSoftUIController } from 'context/softUI.context';

interface WithIcon {
  icon: ReactNode | boolean;
  direction: 'none' | 'left' | 'right';
}

interface SuiInputProps {
  size?: 'small' | 'medium' | 'large';
  withIcon?: WithIcon;
  error?: boolean;
  success?: boolean;
  customClass?: string;
  disabled?: boolean;
  onChange?: any;
  placeholder?: string;
  type?: string; // Add type prop
  name?: string;
  [key: string]: any;
}

const SuiInput = forwardRef<HTMLDivElement, SuiInputProps>(
  ({ size = 'medium', withIcon = { icon: false, direction: 'none' }, error = false, success = false, customClass = '', disabled = false, onChange, placeholder, type = 'text', name, ...rest }, ref) => {
    let template;
    // const [controller] = useSoftUIController();
    // const { direction } = controller;
    const classes = styles({
      size,
      error,
      success,
      withIcon,
      // direction,
      disabled,
    });

    if (withIcon.icon && withIcon.direction === 'left') {
      template = (
        <SuiBox ref={ref} customClass={clsx(classes.suiInputIcon, customClass)}>
          <SuiBox customClass={classes.suiInputIcon_right}>
            <Icon className={classes.suiInputIcon_icon} fontSize="small">
              {withIcon.icon}
            </Icon>
          </SuiBox>
          <InputBase
            {...rest}
            onChange={onChange}
            placeholder={placeholder}
            type={type} // Use type prop
            className={clsx(classes.suiInput, classes.suiInputIcon_input, {
              [classes.suiInput_error]: error,
              [classes.suiInput_success]: success,
              [classes[`suiInput_${size}`]]: size,
            })}
            classes={{
              focused: classes.suiInput_focused,
              disabled: classes.suiInput_disabled,
              error: classes.suiInput_error,
              multiline: classes.suiInput_multiline,
            }}
            name={name}
          />
        </SuiBox>
      );
    } else if (withIcon.icon && withIcon.direction === 'right') {
      template = (
        <SuiBox customClass={clsx(classes.suiInputIcon, customClass)}>
          <InputBase
            {...rest}
            onChange={onChange}
            placeholder={placeholder}
            type={type} // Use type prop
            className={clsx(classes.suiInput, classes.suiInputIcon_input, {
              [classes.suiInput_error]: error,
              [classes.suiInput_success]: success,
              [classes[`suiInput_${size}`]]: size,
            })}
            classes={{
              focused: classes.suiInput_focused,
              disabled: classes.suiInput_disabled,
              error: classes.suiInput_error,
              multiline: classes.suiInput_multiline,
            }}
            name={name}
          />
          <SuiBox customClass={classes.suiInputIcon_right}>
            <Icon className={classes.suiInputIcon_icon} fontSize="small">
              {withIcon.icon}
            </Icon>
          </SuiBox>
        </SuiBox>
      );
    } else {
      template = (
        <InputBase
          {...rest}
          placeholder={placeholder}
          onChange={onChange}
          className={clsx(classes.suiInput, customClass, {
            [classes.suiInput_error]: error,
            [classes.suiInput_success]: success,
            [classes[`suiInput_${size}`]]: size,
          })}
          classes={{
            focused: classes.suiInput_focused,
            disabled: classes.suiInput_disabled,
            error: classes.suiInput_error,
            multiline: classes.suiInput_multiline,
          }}
          name={name}
        />
      );
    }

    return template;
  }
);

export default SuiInput;
