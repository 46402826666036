import axios from './index';
import { AxiosResponse } from 'axios';
import User from '../types/user';

interface LoginResponse {
  success: boolean;
  user: {
    username: string;
  };
  token: string;
}

interface AuthData {
  [key: string]: any;
}

class AuthApi {
  static base = 'auth'; // Adjusted base URL to match Django's URL pattern

  static Register = (data: AuthData): Promise<AxiosResponse> => {
    return axios.post(`${this.base}/register`, data);
  };

  static Logout = (data: { token: string }): Promise<AxiosResponse> => {
    console.log('Logging out with token:', data.token); // Log the token being used for logout

    return axios
      .post(`${this.base}/logout`, data, {
        headers: { Authorization: `${data.token}` },
      })
      .then((response: AxiosResponse) => {
        console.log('Logout successful:', response.data); // Log successful response data
        return response;
      })
      .catch((error: any) => {
        console.error(
          'Logout failed:',
          error.response ? error.response.data : error
        ); // Log error response or error
        throw error;
      });
  };

  static async authenticateGoogle(code: string): Promise<User> {
    const response = await axios.post(`${this.base}/google`, {
      code,
    });
    const { refreshToken, userID } = response.data;
    const user: User = {
      refreshToken: refreshToken,
      userEmail: userID,
    };
    console.log(user);
    localStorage.setItem('user', JSON.stringify(user));
    return user;
  }

  static async authenticateMicrosoft(accessToken: string): Promise<void> {
    const response = await axios.get(`${this.base}/microsoft`);

    // // Extract user and token from the response data
    // const { user, token } = response.data;
    //
    // // Create a User object
    // const userData: User = {
    //   ...user,
    //   refreshToken: token,
    //   userEmail: user.email || '',
    // };
    //
    // // Store the user data in localStorage
    // localStorage.setItem('user', JSON.stringify(userData));
    //
    // // Return the User object
    // return userData;
  }
}

export default AuthApi;
